/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom/client";
import "../style/css/custom.scss";

import App from "./App/app";
import AppProviders from "./AppProviders";

const userId = localStorage.getItem("userId");

(async () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <AppProviders>
      <App id={userId} />
    </AppProviders>
  );
})();
